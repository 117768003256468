import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import {
    Button,
    Divider,
    FormControl,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";

import useAlert from "src/helpers/useAlert";

import CustomSwitch from "src/components/CustomSwitch";
import CustomAutocomplete from "src/components/CustomAutocomplete";

import { postProbationer, signupProbationer, clearPasswordAttemptsById } from "src/services/probationerServices";
import { getOfficers, getOfficerById, getVILODates } from "src/services/officerServices";
import { getLawAndOrderProgress, getLawAndOrderStatus, unlockLawAndOrder } from "src/services/lawandorderServices";
import { IcCheck, IcStopSign } from "src/assets/icons";

import useAuth from "src/hooks/useAuth";
import { getVictimImpactProgress, unlockVictimImpact } from "src/services/victimimpactServices";
import DeleteAccountPopup from "src/pages/auth/DeleteAccountPopup";
import useModal from "src/helpers/useModal";

const validationSchema = yup.object({
    email: yup
        .string("Enter client email")
        .email("Enter a valid email")
        .required("Client email is required"),
    crn: yup
        .string("Enter Case Reference Number")
        .required("Case Reference Number is required"),
    activation: yup.boolean(),
});

const ClientForm = (props) => {
    const { action, closeModal, initialValue, createNew } = props;
    const { auth } = useAuth();
    const { setAlert, closeAlert } = useAlert();
    const { setModal } = useModal();
    const navigate = useNavigate();

    const [viProgressState, setViProgressState] = useState(1)
    const [loProgressState, setLoProgressState] = useState(1)

    const [viloDates, setviloDates] = useState({ startdate: null, enddate: null });
    const [gameStates, setGameStates] = useState({
        victimImpactUnlocked: true,
        lawOrderUnlocked: false
    });

    let probationerId = useParams().clientId;

    const [showModal, setShowModal] = useState(false)

    const [officers, setOfficers] = useState([])
    const [officerEmail, setOfficerEmail] = useState('');
    const [taggedOfficer, setTaggedOfficer] = useState({ accountId: '', username: '', fullname: '', officerId: '', });

    const handleSelectOfficer = (event, officerObject) => {
        setOfficerEmail(officerObject.email);
        formik.setFieldValue("officerId", officerObject.officerId)
        setTaggedOfficer(officerObject)
    }

    const handleCreate = async (values) => {
        const response = await signupProbationer(values);
        const { success, error, value } = response.data;
        if (success) {
            let postObj = { probationerId: value.probationerId, officerId: value.officerId };
            const unlockRes = await unlockVictimImpact(postObj);
            setAlert({
                icon: IcCheck,
                title: "Done",
                description: "Probationer account has been created",
                buttonText: "CLOSE",
                type: "SUCCESS",
            });
            closeModal();
        } else {
            setAlert({
                icon: IcStopSign,
                title: "Failed to signup probationer",
                description: typeof error === 'string' ? error : 'Error',
                buttonText: "TRY AGAIN",
                type: "ERROR",
            });
        }
    }

    const handleClearPassword = async () => {
        const response = await clearPasswordAttemptsById(initialValue.probationerId);
        const { success, error } = response.data;
        if (success) {
            setAlert({
                icon: IcCheck,
                title: "Done",
                description: "Password attempts cleared",
                buttonText: "CLOSE",
                type: "SUCCESS",
            });
        } else {
            setAlert({
                icon: IcStopSign,
                title: "Failed to clear password attempts",
                description: typeof error === 'string' ? error : 'Error',
                buttonText: "TRY AGAIN",
                type: "ERROR",
            });
        }
    }

    const handleUpdate = async (values) => {
        if (gameStates.lawOrderUnlocked) {
            let postObj = structuredClone(initialValue);
            postObj.officerId = initialValue.officer.officerId;
            const response = await unlockLawAndOrder(postObj);
            console.log('Unlock Response: ', response);
        }
        const response = await postProbationer(values);
        const { success, error } = response.data;
        if (success) {
            setAlert({
                icon: IcCheck,
                title: "Done",
                description: "Probationer account has been saved",
                buttonText: "CLOSE",
                type: "SUCCESS",
            });
            navigate("/officers/clients");
        } else {
            setAlert({
                icon: IcStopSign,
                title: "Failed to update probationer",
                description: typeof error === 'string' ? error : 'Error',
                buttonText: "TRY AGAIN",
                type: "ERROR",
            });
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const officersResponse = await getOfficers();
            setOfficers(officersResponse.data.value);
            const officerResponse = await getOfficerById(auth.officer.officerId);
            const value = officerResponse.data.value;
            if (officerResponse.data.success && value != null) {
                delete value.isActivated;
                setTaggedOfficer(value);
            }
        }
        fetchData();
    }, []);


    useEffect(() => {
        const fetchVIProgressData = async () => {

            const viProgress = await getVictimImpactProgress(probationerId);
            if (viProgress.data.success) {
                let progress = viProgress.data.value?.VI_progress
                switch (progress) {
                    case 1:
                        setViProgressState({
                            value: 50,
                            label: "Gameplay"
                        });
                        break;
                    case 2:
                        setViProgressState({
                            value: 67,
                            label: "Types of Victims"
                        });
                        break;
                    case 3:
                        setViProgressState({
                            value: 84,
                            label: "Types of Hurts"
                        });
                        break;
                    case 4:
                        setViProgressState({
                            value: 100,
                            label: "Making Amends"
                        });
                        break;
                    default:
                        setViProgressState({
                            value: 0,
                            label: "Account Started"
                        });
                        break;
                }
            }
        }
        fetchVIProgressData();
    }, []);

    useEffect(() => {
        const fetchLOProgressData = async () => {

            const viProgress = await getLawAndOrderProgress(probationerId);
            if (viProgress.data.success) {
                let progress = viProgress.data.value?.LO_progress
                console.log('progress: ', progress);
                switch (progress) {
                    case 1:
                        setLoProgressState({
                            value: 50,
                            label: "Gameplay"
                        });
                        break;
                    case 2:
                        setLoProgressState({
                            value: 75,
                            label: "Types of Sentencing"
                        });
                        break;
                    case 3:
                        setLoProgressState({
                            value: 100,
                            label: "Cost of Crime"
                        });
                        break;
                    default:
                        setLoProgressState({
                            value: 0,
                            label: "Account Started"
                        });
                        break;
                }
            }
        }
        fetchLOProgressData();
    }, []);

    useEffect(() => {
        // console.log('initial value: ', initialValue);
        const fetchTaggedOfficer = async () => {
            if (!createNew) {
                if (initialValue.probationerId !== '') {
                    let postObj = structuredClone(initialValue);
                    postObj.officerId = initialValue.officer.officerId;

                    // get vilo dates
                    const datesRes = await getVILODates(initialValue.probationerId);
                    if (datesRes.data.success) {
                        if (!(datesRes.data.value == null)) {
                            setviloDates(datesRes.data.value);
                        }
                    }

                    const loStatusResponse = await getLawAndOrderStatus(postObj);
                    const newGameStates = structuredClone(gameStates);
                    newGameStates.lawOrderUnlocked = loStatusResponse.data.value;
                    setGameStates(newGameStates);
                    // if (initialValue !== undefined) {
                    // console.log('officer id: ', initialValue);
                    const response = await getOfficerById(initialValue.officer.officerId);
                    const value = response.data.value;
                    // console.log('officer res val: ', response);
                    delete value.isActivated;
                    setTaggedOfficer(value)
                }
            }
        }
        fetchTaggedOfficer();
    }, [initialValue])


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            probationerId: initialValue?.probationerId || "",
            crn: initialValue?.crn || "",
            email: initialValue?.username || "",
            startdate: initialValue?.startdate || null,
            enddate: initialValue?.enddate || null,
            activation: initialValue?.isActivated === 0 ? false : true || false,
            officerId: initialValue?.officer?.officerId || taggedOfficer.officerId,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                if (action === "CREATE") {
                    handleCreate(values);
                }
                else if (action === "UPDATE") {
                    handleUpdate(values);
                }
                else {
                    alert("invalid action");
                }
            } catch (error) {
                alert("an error occured");
                // console.log(error);
            }
        },
    });

    return (
        <div>
            <Typography variant="h3" color="secondary" sx={{ mb: 2 }}>
                {action === "CREATE" ? "Create" : "Edit"} Client
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <Typography variant="label">
                            Case Reference Number{" "}
                            <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                            id="crn"
                            name="crn"
                            size="small"
                            value={formik.values.crn}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.crn &&
                                Boolean(formik.errors.crn)
                            }
                            helperText={
                                formik.touched.crn &&
                                formik.errors.crn
                            }
                            InputLabelProps={{ shrink: false }}
                        />
                    </FormControl>

                    <FormControl fullWidth sx={{ my: 1 }}>
                        <Typography variant="label">
                            Client Email <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                            id="email"
                            name="email"
                            size="small"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                            }
                            helperText={
                                formik.touched.email &&
                                formik.errors.email
                            }
                            InputLabelProps={{ shrink: false }}
                        />
                    </FormControl>

                    <FormControl fullWidth sx={{ my: 1 }}>
                        <Typography variant="label">
                            Programme Start Date{" "}
                            <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <MobileDatePicker
                            onChange={(value) =>
                                formik.setFieldValue("startdate", value, true)
                            }
                            value={formik.values.startdate}
                            renderInput={(params) => (
                                <TextField
                                    error={Boolean(
                                        formik.touched.startdate &&
                                        formik.errors.startdate
                                    )}
                                    helperText={
                                        formik.touched.startdate &&
                                        formik.errors.startdate
                                    }
                                    name="startdate"
                                    size="small"
                                    InputLabelProps={{ shrink: false }}
                                    {...params}
                                />
                            )}
                        />
                    </FormControl>

                    <FormControl fullWidth sx={{ my: 1 }}>
                        <Typography variant="label">
                            Programme End Date{" "}
                            <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <MobileDatePicker
                            onChange={(value) =>
                                formik.setFieldValue("enddate", value, true)
                            }
                            value={formik.values.enddate}
                            renderInput={(params) => (
                                <TextField
                                    error={Boolean(
                                        formik.touched.enddate &&
                                        formik.errors.enddate
                                    )}
                                    helperText={
                                        formik.touched.enddate &&
                                        formik.errors.enddate
                                    }
                                    name="enddate"
                                    size="small"
                                    InputLabelProps={{ shrink: false }}
                                    {...params}
                                />
                            )}
                        />
                    </FormControl>

                    {action !== "CREATE" && <>
                        {/* Account Activation */}
                        <FormControl fullWidth sx={{ my: 1 }}>
                            <Typography variant="label">
                                Account Activation
                            </Typography>
                            <Stack direction="row" alignItems="center">
                                <CustomSwitch
                                    formikName='activation'
                                    formikID='activation'
                                    activated={formik.values.activation}
                                    inactiveText='Disabled'
                                    activeText='Active'
                                    callback={formik.handleChange}
                                />
                            </Stack>
                        </FormControl>
                        <p style={{ whiteSpace: "nowrap" }}>Click <span
                            style={{ color: 'blue', cursor: 'pointer' }}
                            onClick={() => {
                                handleClearPassword();
                            }}
                        >here</span> to Unlock Account</p>

                        {/* Game Progress */}
                        <FormControl fullWidth sx={{ my: 1 }}>
                            <Typography variant="label">
                                Game Progress
                            </Typography>
                            <Stack direction="column" alignItems="flex-start">
                                <Typography sx={{ width: '100%', textAlign: 'left' }}>Victim Impact</Typography>
                                <progress value={viProgressState.value} max="100" style={{ width: '100%', height: 1, padding: 5 }} />
                                <Typography sx={{ width: '100%', textAlign: 'left' }}>Stage: {viProgressState.label} ({viProgressState.value}%)</Typography>
                            </Stack>

                            <Stack direction="column" alignItems="flex-start" style={{ marginTop: 20 }}>
                                <Typography sx={{ width: '100%', textAlign: 'left' }}>Law and Order</Typography>
                                <progress value={loProgressState.value} max="100" style={{ width: '100%', height: 1, padding: 5 }} />
                                <Typography sx={{ width: '100%', textAlign: 'left' }}>Stage: {loProgressState.label} ({loProgressState.value}%)</Typography>
                            </Stack>
                        </FormControl>
                    </>}
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <Typography variant="label">
                            Officer{" "}
                            <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <CustomAutocomplete
                            className='auto'
                            // id="officer-name"
                            options={officers}
                            onChange={handleSelectOfficer}
                            getOptionLabel={(o) => o.fullname}
                            value={createNew ? taggedOfficer : officers === [] ? { fullname: '' } : taggedOfficer}
                            // disabled={ auth.officer.officerId === taggedOfficer.officerId || auth.isSuperAdmin ? true : false}
                            disabled={auth.isSuperAdmin ? false : true}
                            renderInput={(params) => <TextField
                                fullWidth
                                id="officer"
                                name="officer"
                                size="small"
                                // label={taggedOfficer.fullname}
                                value={createNew ? formik.values.officer : taggedOfficer.fullname}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.officer &&
                                    Boolean(formik.errors.officer)
                                }
                                helperText={
                                    formik.touched.officer && formik.errors.officer
                                }
                                {...params} />}
                        />
                    </FormControl>

                    <FormControl fullWidth sx={{ my: 1 }}>
                        <Typography variant="label">
                            Officer Email{" "}
                            <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                            fullWidth
                            id="officerEmail"
                            name="officerEmail"
                            size="small"
                            // value={createNew? officerEmail : taggedOfficer.email}
                            value={createNew & auth.isSuperAdmin ? officerEmail : taggedOfficer.username}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.officerEmail &&
                                Boolean(formik.errors.officerEmail)
                            }
                            helperText={
                                formik.touched.officerEmail &&
                                formik.errors.officerEmail
                            }
                            InputLabelProps={{ shrink: false }}
                        />
                    </FormControl>

                    <FormControl fullWidth sx={{ my: 1 }}>
                        <Typography variant="label">Unlock Game</Typography>
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ mt: "4px" }}
                        >
                            <Typography sx={{ width: "150px" }}>
                                Victim Impact
                            </Typography>
                            <CustomSwitch
                                activated={gameStates.victimImpactUnlocked}
                                inactiveText='Lock'
                                activeText='Unlock'
                            // callback={(event) => {
                            //     console.log();
                            // }}
                            />
                        </Stack>
                        <Stack direction="row" alignItems="center">
                            <Typography sx={{ width: "150px" }}>
                                Law {"&"} Order
                            </Typography>
                            <CustomSwitch
                                activated={gameStates.lawOrderUnlocked}
                                inactiveText='Lock'
                                activeText='Unlock'
                                callback={(event) => {
                                    const newGameStates = structuredClone(gameStates);
                                    newGameStates.lawOrderUnlocked = event.target.checked;
                                    setGameStates(newGameStates);
                                }}
                            />
                        </Stack>
                    </FormControl>

                    <FormControl fullWidth sx={{ my: 1 }}>
                        <Typography variant="label">
                            VI {"&"} LO Start Date
                        </Typography>
                        <MobileDatePicker
                            disabled={true}
                            value={viloDates.startdate}
                            renderInput={(params) => (
                                <TextField
                                    error={Boolean(
                                        formik.touched.startdate &&
                                        formik.errors.startdate
                                    )}
                                    helperText={
                                        formik.touched.startdate &&
                                        formik.errors.startdate
                                    }
                                    name="startDdte"
                                    size="small"
                                    InputLabelProps={{ shrink: false }}
                                    {...params}
                                />
                            )}
                        />
                    </FormControl>

                    <FormControl fullWidth sx={{ my: 1 }}>
                        <Typography variant="label">
                            VI {"&"} LO End Date
                        </Typography>
                        <MobileDatePicker
                            disabled={true}
                            onChange={(value) =>
                                formik.setFieldValue("enddate", value, true)
                            }
                            value={viloDates.enddate}
                            renderInput={(params) => (
                                <TextField
                                    error={Boolean(
                                        formik.touched.enddate &&
                                        formik.errors.enddate
                                    )}
                                    helperText={
                                        formik.touched.enddate &&
                                        formik.errors.enddate
                                    }
                                    name="enddate"
                                    size="small"
                                    InputLabelProps={{ shrink: false }}
                                    {...params}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <Divider sx={{ mt: 5, mb: 3 }} />

            <Grid
                container
                rowSpacing={1}
                alignItems="center"
                justifyContent="space-between"
                sx={{ background: '', display: 'flex', flexDirection: 'row' }}
            >
                <Grid item md={6} sx={{ background: '' }}>
                    <Typography>
                        <span style={{ color: "red" }}>*</span> Mandatory fields
                    </Typography>
                </Grid>
                <Grid item md={6} sx={{ width: '100%', mt: { xs: '20px', md: '0px' }, background: '', display: 'flex', justifyContent: { xs: 'center', md: "right" } }}>
                    <div
                        style={{ display: "flex", gap: 10, alignItems: "center", flexDirection: "row" }}
                    >
                        {action === "UPDATE" && <Button
                            color="secondary"
                            variant="rounded"
                            sx={{ minWidth: "200px", backgroundColor: "red", fontSize: "16px" }}
                            onClick={() => {
                                setModal(
                                    <>
                                        <DeleteAccountPopup id={initialValue?.probationerId} type={"probationer"} />
                                    </>
                                    , "462px")
                            }}
                        >
                            Archive Account
                        </Button>}
                        <Button
                            color="primary"
                            variant="rounded"

                            sx={{ minWidth: "200px", fontSize: "16px" }}

                            onClick={formik.handleSubmit}
                        >
                            {props.buttonText}
                        </Button>
                    </div>

                </Grid>
            </Grid>
        </div>
    );
};

export default ClientForm;
