import React from 'react'
import { Button, Box, Container, Divider, TextField, Typography } from "@mui/material";

import { useState, useEffect } from 'react';
import {useNavigate} from "react-router-dom"
import useModal from "src/helpers/useModal";
import useAlert from "src/helpers/useAlert";
import useSession from 'src/hooks/useSession';
import useAuth from 'src/hooks/useAuth';

import {
    getQuestionData,
    getDraftAnswers,
    postLawAndOrderResponses,
    endLawAndOrderSession,
    getLawAndOrderProgress,
    getActiveSession,
} from 'src/services/lawandorderServices';
import { 
    endSessionByProbationerId,
    getGameProgressByProbationerId
 } from 'src/services/gameServices';

import { IcLegalHammerBlack, IcCheck, IcStopSign } from 'src/assets/icons'

// import { getGameProgressByProbationerId, endSessionByProbationerId } from 'src/services/gameServices';

import Header from '../../../../components/Header'
import AnswersSavedPopup from '../../popups/AnswersSavedPopup';


const CostOfCrime2 = () => {
    const navigate = useNavigate();
    const { setModal } = useModal();
    const { setAlert } = useAlert();
    const { session, setSession } = useSession();
    const { auth, setAuth } = useAuth();

    const [shownQuestions, setShownQuestions] = useState(1);
    const [ questions, setQuestions ] = useState([]);
    const [answers, setAnswers] = useState([]);

    var count = 0;

    const handleNext = event => {
        if (shownQuestions < 5) {
            setShownQuestions(shownQuestions + 1);
        }
    }

    const handleText = (index) => event => {
        let newAnswers = structuredClone(answers);
        newAnswers[index].answer = event.target.value;
        setAnswers(newAnswers);
    }

    const handleSubmit = async () => {
        // console.log('answers: ', answers);
        for (const ans of answers) {
            if (ans.answer === '') {
                setAlert({
                    title: "Error",
                    icon: IcStopSign,
                    description: "Do not leave any blanks.",
                    buttonText: "OK",
                    type: "OK",
                });
                return
            }
        }
        const postObj = {
            sessionId: session.CoC_sessionId,
            isDraft: 0,
            questionIds: [],
            responses: [],
        };
        for (const answer of answers) {
            postObj.questionIds.push(answer.questionId)
            postObj.responses.push(answer.answer)
        }
        const response = await postLawAndOrderResponses(auth.probationer.probationerId, postObj);
        // console.log('response: ', response);
        if (response.data.success) {
            setAlert({
                title: "Success",
                icon: IcCheck,
                description: "Answers have been successfully submitted.",
                buttonText: "OK",
                type: "OK",
                // nextDest: 'players/final-evaluation'
            });
            const res = await endSessionByProbationerId(auth.probationer.probationerId, 2);
        }
        await endLawAndOrderSession(postObj.sessionId);
        // Get Sidebar game, LO Progress
        const gameProgress = await getGameProgressByProbationerId(auth.probationer.probationerId);
        const loProgress = await getLawAndOrderProgress(auth.probationer.probationerId);
        const newSession = structuredClone(session);
        newSession.LO_progress = loProgress.data.value.LO_progress;
        newSession.gameProgress = gameProgress.data.value.gameProgress;
        await setSession(newSession);
        navigate('/players/final-evaluation')
    }

	useEffect(() => {
        const fetchData = async() => {
            count++;
            // get CostOfCrime questions
            const response = await getQuestionData(3);
            if (response.data.success) {
                await setQuestions(response.data.value);
                // Get CoC sessionId
                const res = await getActiveSession(auth.probationer.probationerId, 3);
                if (res.data.success) {
                    const newSession = structuredClone(session);
                    newSession.CoC_sessionId = res.data.value.sessionId;
                    // console.log('Setting CoC ID:', newSession);
                    await setSession(newSession);
                }
            }
        }
        if (count < 1) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        // console.log('session update: ', session);
        const fetchDraft = async() => {
            if ('CoC_sessionId' in session && questions.length !== 0) {
                const draftResponse = await getDraftAnswers(session.CoC_sessionId);
                const newAnswers = structuredClone(answers);
                questions.forEach((q, i) => {
                    newAnswers.push(
                        {
                            answer: '',
                            questionId: q.questionId,
                        }
                    )
                })
                draftResponse.data.value.forEach((q, i) => {
                    newAnswers[i].answer = q.response;
                })
                setAnswers(newAnswers);
            }
        }
        fetchDraft();
    }, [session]);

    return (
        <>
            <Header
                    title='Cost of Crime'
                    text='Think about your own offence and how you impacted others. Write down your reflections below.'
                    icon={IcLegalHammerBlack}
                    iconSize='70%'/>
            <Divider sx={{ mt: {xs: '10px', md:"0px"}, mb: {xs: "20px", md: "20px"}}}/>
            <Container sx={{p:0, background: '', display: 'flex', flexDirection:{xs:'column', md: 'row'}, mb:'5%'}}>
                
                <Box sx={{display:'flex', background:'', height:'295px', justifyContent: {xs:'center', md:'left'}}}>
                    <img
                        style={{width:"292px"}}
                        src={require("src/assets/illustration/questions-thinking.png").default}
                        alt="Thinking"
                    />
                </Box>
                <Divider orientation='vertical' sx={{ m:{md: '20px'}}}/>
                <Box sx={{background:'', width:"100%", display:'flex', flexDirection:'column'}}>
                    {questions.slice(0, shownQuestions).map((q, i) => {
                        return (
                            <Box key={i} sx={{p:2, background:'#F5F5F5', height:'55%', width:'100%', mt:'20px'}}>
                                <Typography sx={{mb:'5px'}}>
                                    {q.title}<span style={{ color: "red" }}>*</span>
                                </Typography>
                                <TextField
                                    value={answers.length === 0? '' : answers[i].answer}
                                    multiline={true}
                                    rows='3'
                                    sx={{
                                        width:'100%',
                                        background:'white'
                                    }}
                                    onChange={handleText(i)}/>
                            </Box>
                        )
                    })}

                    <Box sx={{display:'flex', background:'', flexDirection: {xs:'column', md:'row'}, justifyContent:'space-between', mt:'20px'}}>
                        <Box sx={{background:'', display:'flex', flexDirection:'column'}}>
                            <Typography sx={{ ml: '10px', display:{xs:'block', md:'none'}, mb:'20px'}}>
                                <span style={{ color: "red" }}>*</span>Mandatory
                            </Typography>
                            <Typography sx={{ml: {xs: '10px', md: '0'}, color:'#4FAFCD', mb:{xs:'15px', md:'0'}}}>
                                Question {shownQuestions} of 5
                            </Typography>
                            <Typography sx={{display:{xs:'none', md:'block'}, mt:'20px'}}>
                                <span style={{ color: "red" }}>*</span>Mandatory
                            </Typography>
                            
                        </Box>
                        
                        {shownQuestions === 5
                            ? (
                                <Box sx={{display:'flex', justifyContent:"center"}}>
                                    <Button
                                        sx={{fontSize:"16px", width:{xs:"90%", md:"239px"}, height:"39px" }}
                                        variant="rounded"
                                        fullWidth
                                        size="small"
                                        onClick={handleSubmit}
                                    >
                                        SUBMIT
                                    </Button>
                                </Box>
                            )
                            : (
                                <Box sx={{background:'', display:'flex', flexDirection: {xs:'column', md:'row'}, alignItems:'center'}}>
                                    <Button
                                        sx={{background:'white', color: "#4FAFCD", border: '1px solid #4FAFCD', fontSize:"16px", width:{xs:"90%", md:"239px"}, height:"39px", }}
                                        variant="rounded"
                                        fullWidth
                                        size="small"
                                        onClick={async () => {
                                            const postObj = {
                                                sessionId: session.CoC_sessionId,
                                                isDraft: 1,
                                                questionIds: [],
                                                responses: [],
                                            };
                                            for (const answer of answers) {
                                                postObj.questionIds.push(answer.questionId)
                                                postObj.responses.push(answer.answer.toString())
                                            }
                                            const response = await postLawAndOrderResponses(auth.probationer.probationerId, postObj);
                                            if (response.data.success) {
                                                setModal(
                                                    <>
                                                        <AnswersSavedPopup/>
                                                    </>
                                                , '462px');
                                            }
                                        }}
                                    >
                                        SAVE & CONTINUE LATER
                                    </Button>
                                    <Divider orientation='vertical' sx={{ height:'0px', width:{xs: '0px', md: '3%'}, margin:'5px'}} />
                                    <Button
                                        sx={{fontSize:"16px", width:{xs:"90%", md:"239px"}, height:"39px" }}
                                        variant="rounded"
                                        fullWidth
                                        size="small"
                                        onClick={handleNext}
                                    >
                                        NEXT
                                    </Button>
                                </Box>
                            )
                        }
                        
                    </Box>
                </Box>
            </Container>

        </>
    )
}

export default CostOfCrime2