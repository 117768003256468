import axios from "axios";
import errorResponseHandler from "./errorResponseHandler";
import interceptResponseHandler from "./interceptResponseHandler";

const instance = axios.create({
    withCredentials: true,
    baseURL: "https://myprobationjourneybackend.msf.gov.sg",
    // baseURL: "http://localhost:4001",
    // baseURL: process.env.REACT_APP_BACKEND_URL,
    
    
    // Use this 
    // baseURL: "https://lawandorderstagingbackend.empirecode.co",
    // baseURL: "http://lawandorderstagingbackend.empirecode.co:4001",
    timeout: 1000 * 10,
});

instance.interceptors.response.use(interceptResponseHandler, errorResponseHandler);

export default instance;
