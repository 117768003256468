import { React, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Box, Drawer, Divider } from "@mui/material";

const Footer = (props) => {

    const [ footerDisplay, setFooterDisplay ] = useState(true);

    useEffect(() => (
        window.addEventListener('locationchange', () => {
            if (window.location.href.includes('/players/victim-impact/play') && window.screen.width > window.screen.height && window.screen.width < 1000) {
                setFooterDisplay(false);
            } else {
                setFooterDisplay(true);
            }
        }),
        () => window.removeEventListener('locationchange', () => {
            if (window.location.href.includes('/players/victim-impact/play') && window.screen.width > window.screen.height && window.screen.width < 1000) {
                setFooterDisplay(false);
            } else {
                setFooterDisplay(true);
            }
        })
    ))

    useEffect(() => (
        window.addEventListener('resize', () => {
            // console.log('condition: ', window.location.href.includes('/players/victim-impact/play') && window.screen.width > window.screen.height);
            if (window.location.href.includes('/players/victim-impact/play') && window.screen.width > window.screen.height  && window.screen.width < 1000) {
                setFooterDisplay(false);
            } else {
                setFooterDisplay(true);
            }
        }),
        () => window.removeEventListener('resize', () => {
            if (window.location.href.includes('/players/victim-impact/play') && window.screen.width > window.screen.height && window.screen.width < 1000) {
                setFooterDisplay(false);
            } else {
                setFooterDisplay(true);
            }
        })
    ))

    const text = props.officer? "" : "The content on this website is not intended to constitute or be relied upon as legal advice. You should consult a qualified lawyer if you require legal advice.";
    return (
        <>
            <Container maxWidth={false} sx={{
                display: {md: "none", xs: footerDisplay? "flex": 'none'},
                flexDirection: "column",
                justifyContent: "left",
                textAlign: 'left',
                paddingTop: "20px",
                background: "#000000 0% 0% no-repeat padding-box",
                height: "215px",
                letterSpacing: "var(--unnamed-character-spacing-0)",
                font: "normal normal normal 12px/16px Frutiger LT Std",
                letterSpacing: "0px",
                color: "#A2A2A2",
                opacity: 1,
            }}>
                <Box sx={{fontSize:"12pt"}}>
                    {text}
                    <br></br>
                    ©{new Date().getFullYear()} Ministry of Social and Family Development
                </Box>
                <Divider sx={{height:'10px'}}/>
                <Box sx={{ width: "117px", height: "98px", }}>
                    <img
                        style={{width:"auto", height:"70%"}}
                        src={require("src/assets/illustration/MSF-logo-white-highres.png").default}
                        alt="White MSF Logo"
                    />
                </Box>
            </Container>
        
            <Drawer 
                anchor="bottom"
                variant="permanent"
                PaperProps={{
                    sx: {
                        backgroundColor: "#000000",
                        height: "12vh",
                        width:"100%",
                        display: footerDisplay?
                        {
                            xs: "none",
                            sm: "none",
                            md: 'flex'
                        }
                        :
                        {
                            xs: "none",
                            sm: "none",
                            md: 'none'
                        },
                        alignItems:"center",
                        justifyContent: "space-between",
                        flexDirection:"row",
                    },
                }}
            >
                <Box sx={styles.text}>
                    {text}
                    <br></br>
                    ©{new Date().getFullYear()} Ministry of Social and Family Development
                </Box>
                <img
                    style={{width:"auto", height:"70%"}}
                    src={require("src/assets/illustration/MSF-logo-white-highres.png").default}
                    alt="White MSF Logo"
                />
            </Drawer>
        </>
    );
};

const styles = {
    mobile: {
        display: {md: "none", xs: "flex"},
        flexDirection: "column",
        justifyContent: "left",
        textAlign: 'left',
        paddingTop: "20px",
        background: "#000000 0% 0% no-repeat padding-box",
        height: "215px",
        letterSpacing: "var(--unnamed-character-spacing-0)",
        font: "normal normal normal 12px/16px Frutiger LT Std",
        letterSpacing: "0px",
        color: "#A2A2A2",
        opacity: 1,
    },
    text: {
        fontSize:"12px",
        font: "Frutiger LT Std",
        color:"#A2A2A2",
        paddingLeft:"5px",
    }
}

export default Footer;
