import { React } from 'react'
import * as yup from "yup";
import { useFormik } from "formik";
import useAlert from "src/helpers/useAlert";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { TextField, Button, Box, Container, Typography } from "@mui/material";

import { resetPassword } from 'src/services/authServices';
import { SettingsAccessibility } from '@mui/icons-material';

import { IcCheck, IcStopSign } from "src/assets/icons";

const validationSchema = yup.object({
    newPassword: yup
        .string("Enter your password")
        .min(12, "Password should be of minimum 12 characters length")
        .required("Password is required"),
    confirmPassword: yup
        .string("Enter your password")
        .min(12, "Password should be of minimum 12 characters length")
        .required("Password is required"),
});

const PasswordReset = () => {
    const { setAlert } = useAlert();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token");

    const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: validationSchema,
    });

    return (
        <Container sx={styles.container}>
                <img
                    width="300px"
                    src={require("src/assets/common/msf-logo.png").default}
                    alt="MSF Logo"
                />
                <Typography align="center" variant="h2" color="black" sx={{ mb: "20px" }}>
                    Probation and Community Rehabilitation Service
                    <br/>
                    My Probation Journey
                </Typography>
                <Box sx={styles.box}>
                    <Typography variant="h2" color="white" sx={{ mb: "10px" }}>
                        Reset Password
                    </Typography>
                    <form >
                        <TextField
                            fullWidth
                            id="newPassword"
                            name="newPassword"
                            label="New Password"
                            type="password"
                            variant="filled"
                            size="small"
                            sx={styles.input}
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.newPassword && Boolean(formik.errors.newPassword)
                            }
                            helperText={formik.touched.newPassword && formik.errors.newPassword}
                        />
                        <TextField
                            fullWidth
                            id="confirmPassword"
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            variant="filled"
                            size="small"
                            sx={styles.input}
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.confirmPassword &&
                                Boolean(formik.errors.confirmPassword)
                            }
                            helperText={
                                formik.touched.confirmPassword && formik.errors.confirmPassword
                            }
                        />
                        <Button
                            onClick={async () => {
                                // console.log('values: ', formik.values);
                                if (formik.values.newPassword === formik.values.confirmPassword) {
                                    if (formik.values.newPassword.length < 12) {
                                        setAlert({
                                            icon: IcStopSign,
                                            title: "Password too short",
                                            description: "Password should be of minimum 12 characters length.",
                                            buttonText: "OK",
                                            type: "OK",
                                        });
                                        return
                                    }
                                    const pw = formik.values.newPassword;
                                    var cats = 0;
                                    const uppercase = pw.length - pw.replace(/[A-Z]/g, '').length;
                                    const lowercase = pw.length - pw.replace(/[a-z]/g, '').length;
                                    const numbers = pw.length - pw.replace(/[0-9]/g, '').length;
                                    const spec = pw.length - pw.replace(/[^A-Za-z0-9]/g, '').length;
                                    // console.log([uppercase, lowercase, numbers, spec]);
                                    for (const cat of [uppercase, lowercase, numbers, spec]) {
                                        if (cat > 0) {
                                            cats++;
                                        }
                                    }
                                    if (cats < 2) {
                                        setAlert({
                                            icon: IcStopSign,
                                            title: "Password requirement failed",
                                            description: `Password should include at least 2 of the following: Upper or lower case characters, digits, or special characters.`,
                                            buttonText: "OK",
                                            type: "OK",
                                        });
                                        return
                                    }
                                    // console.log({
                                    //     token: token,
                                    //     newPassword: formik.values.newPassword
                                    // });
                                    const response = await resetPassword({
                                        token: token,
                                        newPassword: formik.values.newPassword
                                    });
                                    // console.log('response: ', response);
                                    if (response.data.success) {
                                        setAlert({
                                            icon: IcCheck,
                                            title: "Success",
                                            description: "Password changed successfully!",
                                            buttonText: "OK",
                                            type: "OK",
                                            nextDest: "/client-login"
                                        })
                                    }
                                } else {
                                    setAlert({
                                        icon: IcStopSign,
                                        title: "Passwords do not match",
                                        description: "Make sure your passwords match.",
                                        buttonText: "OK",
                                        type: "OK",
                                    })
                                }
                            }}
                            color="green"
                            variant="contained"
                            fullWidth
                            // type="submit"
                            sx={{fontSize: '17px'}}
                        >
                            CONFIRM
                        </Button>
                    </form>
                </Box>
            </Container>
    )
    }

const styles = {
    container: {
        width: "100%",
        // background: "lightgrey", 
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: {xs: "30px", md: "40px"},
    },
    box: {
        position: "relative",
        textAlign: "left",
        mx: "auto",
        borderRadius: "20px",
        maxWidth: "439px",
        height: {xs: "308px"}, 
        padding: {xs: "40px 40px", md: "40px 62px"},
        background:
            "transparent linear-gradient(180deg, #2F4D8F 0%, #231E54 100%) 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
    },
    input: {
        mb: "13px",
        input: {
            borderRadius: "5px",
            color: "#000000",
            background: "#FFFFFF",
        },
    },
};

export default PasswordReset