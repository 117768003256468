import React from 'react'
import { Button, Box, Container, Divider, Slider, Typography } from "@mui/material";

import { useState, useEffect } from 'react';
import useModal from "src/helpers/useModal";
import useAlert from "src/helpers/useAlert";
import useSession from 'src/hooks/useSession';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import {
    getDraftAnswers,
    postLawAndOrderResponses,
    endLawAndOrderSession,
    getLawAndOrderProgress,
    getActiveSession,
    getQuestionData,
} from 'src/services/lawandorderServices';

import { IcLawBlack, IcCheck } from "src/assets/icons";

import Header from '../../../components/Header'
import AnswersSavedPopup from '../popups/AnswersSavedPopup';
import FinesPopup from '../popups/FinesPopup';
import { logDOM } from '@testing-library/react';

const marks = [
    { value: 10, label: '10', },
    { value: 11, label: '11', },
    { value: 12, label: '12', },
    { value: 13, label: '13', },
    { value: 14, label: '14', },
    { value: 15, label: '15', },
    { value: 16, label: '16', },
    { value: 17, label: '17', },
    { value: 18, label: '18', },
    { value: 19, label: '19', },
    { value: 20, label: '20', },
    { value: 21, label: '21', },
    { value: 22, label: '22', },
    { value: 23, label: '23', },
    { value: 24, label: '24', },
    { value: 25, label: '25', },
];

const marksMobile = [
    { value: 10, label: '10', },
    { value: 15, label: '15', },
    { value: 20, label: '20', },
    { value: 25, label: '25', },
];

const TypesOfSentencing = () => {
    const { setModal } = useModal();
    const { setAlert } = useAlert();
    const { session, setSession } = useSession();
    const { auth } = useAuth();
    const navigate = useNavigate();

    const [answers, setAnswers] = useState([]);

    const [shownQuestions, setShownQuestions] = useState(0);
    const [questions, setQuestions] = useState([]);

    var count = 0;

    const handleSlider = (id) => (event, newValue) => {
        let newAnswers = structuredClone(answers);
        newAnswers[id].answer = newValue;
        setAnswers(newAnswers);
    };

    const handleNext = event => {
        if (shownQuestions < 6) {
            setShownQuestions(shownQuestions + 1);
        }
    }

    const handleSubmit = async () => {
        const postObj = {
            sessionId: session.ToS_sessionId,
            isDraft: 0,
            questionIds: [],
            responses: [],
        };
        for (const answer of answers) {
            postObj.questionIds.push(answer.questionId)
            postObj.responses.push(answer.answer.toString())
        }
        const response = await postLawAndOrderResponses(auth.probationer.probationerId, postObj);
        if (response.data.success) {
            setAlert({
                title: "Success",
                icon: IcCheck,
                description: "Answers have been successfully submitted.",
                buttonText: "OK",
                type: "OK",
                nextDest: 'players/law-and-order/cost-of-crime/1'
            });
        }
        // alert(postObj.sessionId)
        await endLawAndOrderSession(postObj.sessionId);

        // Get Sidebar LO Progress
        const loProgress = await getLawAndOrderProgress(auth.probationer.probationerId);
        const newSession = structuredClone(session);
        newSession.LO_progress = loProgress.data.value.LO_progress;
        await setSession(newSession);
    }

    useEffect(() => {
        const fetchData = async () => {
            count++;
            // get TypesOfSentencing questions
            const response = await getQuestionData(2);
            if (response.data.success) {
                await setQuestions(response.data.value);
                // Get ToS sessionId
                const tosSessionIdres = await getActiveSession(auth?.probationer?.probationerId, 2);
                if (tosSessionIdres.data.success) {
                    const newSession = structuredClone(session);
                    newSession.ToS_sessionId = tosSessionIdres.data.value.sessionId;
                    // console.log('setting tos_sessionId:', newSession);
                    await setSession(newSession);
                }
            }
        }
        if (count < 1) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        const fetchDraft = async () => {
            if ('ToS_sessionId' in session && questions.length !== 0) {
                const draftResponse = await getDraftAnswers(session.ToS_sessionId);
                const newAnswers = structuredClone(answers);
                questions.forEach((q, i) => {
                    newAnswers.push(
                        {
                            answer: [10, 11],
                            questionId: q.questionId,
                        }
                    )
                })
                draftResponse.data.value.forEach((q, i) => {
                    newAnswers[i].answer = q.response.split(',');
                })
                setAnswers(newAnswers);
            }
        }
        fetchDraft();
    }, [session]);

    // console.log('answers: ', answers);

    return (
        <>
            <Header
                title='Types of Sentencing'
                text={`Click on the "?" to find out more about each option. Drag the dots to match the age range for each sentence.`}
                icon={IcLawBlack}
                iconSize='70%' />

            {/* <Divider sx={{width:'100%', mb:"35px"}}/> */}

            <Divider sx={{ mt: { xs: '10px', md: "0px" }, mb: { xs: "20px", md: "35px" } }} />

            {questions.slice(0, shownQuestions + 1).map((q, i) => {
                return (
                    <div key={i}>
                        <Container sx={{ background: '', display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{
                                ml: { md: '-1%' },
                                background: q.color_code,
                                color: "white",
                                p: 2,
                                borderRadius: '10px',
                                width: { xs: '100%', md: '30%' },
                                fontSize: { xs: '15px' }
                            }}>
                                <Button sx={{
                                    color: 'white',
                                    fontSize: { xs: '15px' },
                                    textTransform: 'none',
                                }}
                                    onClick={() => {
                                        setModal(
                                            <>
                                                <FinesPopup
                                                    title={q.title}
                                                    description={q.description}
                                                />
                                            </>
                                            , '600px');
                                    }}
                                >
                                    <div style={{ display: "flex", flexDirection: "row", gap: 10, alignItems: "center" }}>
                                        <p style={{ margin: 0, padding: 0 }}>{q.title}</p>
                                        <AiOutlineQuestionCircle size={20} color="white" />
                                    </div>
                                </Button>
                            </Box>
                            <Box sx={{ background: '', width: { xs: '95%', md: '65%' }, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography sx={{ mr: "3.25%", fontWeight: "bold" }}>
                                    Age
                                </Typography>
                                <Slider
                                    sx={{ display: { xs: "none", md: "block" }, color: q.color_code }}
                                    value={Object.keys(answers).length === 0 ? [10, 11] : answers[i].answer}
                                    onChange={handleSlider(i)}
                                    valueLabelDisplay="auto"
                                    min={10}
                                    max={25}
                                    step={1}
                                    marks={marks}
                                />
                                <Slider
                                    sx={{ width: '85%', display: { xs: "block", md: "none" }, color: q.color_code }}
                                    value={Object.keys(answers).length === 0 ? [10, 11] : answers[i].answer}
                                    onChange={handleSlider(i)}
                                    valueLabelDisplay="auto"
                                    min={10}
                                    max={25}
                                    step={1}
                                    marks={marksMobile}
                                />
                                <p style={{ marginTop: 42, marginLeft: 10,color: q.color_code }}>{">"}</p>
                            </Box>
                        </Container>
                        <Divider sx={{ width: '100%', mt: { xs: '20px', md: "35px" }, mb: { xs: '20px', md: "35px" } }} />
                    </div>
                )
            })}

            <Container sx={{ mb: '35px', background: '', alignItems: { xs: 'left', md: 'center' }, display: 'flex', justifyContent: 'space-between', flexDirection: { xs: "column", md: 'row' } }}>
                <Typography sx={styles.blueText}>
                    Question {shownQuestions + 1} of 7
                </Typography>
                <Divider orientation='vertical' sx={{ display: { xs: 'flex', md: 'none' }, margin: '10px' }} />
                <Box sx={{ background: '', display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }}>
                    <Button
                        sx={{ background: 'white', color: "#4FAFCD", border: '1px solid #4FAFCD', fontSize: "16px", width: { xs: "90%", md: "239px" }, height: "39px", }}
                        variant="rounded"
                        fullWidth
                        size="small"
                        onClick={async () => {
                            const postObj = {
                                sessionId: session.ToS_sessionId,
                                isDraft: 1,
                                questionIds: [],
                                responses: [],
                            };
                            for (const answer of answers) {
                                postObj.questionIds.push(answer.questionId)
                                postObj.responses.push(answer.answer.toString())
                            }
                            const response = await postLawAndOrderResponses(auth.probationer.probationerId, postObj);
                            if (response.data.success) {
                                setModal(
                                    <>
                                        <AnswersSavedPopup />
                                    </>
                                    , '462px');
                            }
                        }}
                    >
                        SAVE & CONTINUE LATER
                    </Button>
                    <Divider orientation='vertical' sx={{ width: { xs: '0px', md: '3%' }, margin: '5px' }} />
                    {shownQuestions < 6 ?
                        <Button
                            sx={{ fontSize: "16px", width: { xs: "90%", md: "239px" }, height: "39px" }}
                            variant="rounded"
                            fullWidth
                            size="small"
                            onClick={handleNext}
                        >
                            NEXT
                        </Button>
                        :
                        <Button
                            sx={{ fontSize: "16px", width: { xs: "90%", md: "239px" }, height: "39px" }}
                            variant="rounded"
                            fullWidth
                            size="small"
                            onClick={handleSubmit}
                        >
                            SUBMIT
                        </Button>
                    }
                </Box>
            </Container>
        </>
    )
}

const styles = {
    blueText: {
        mt: { md: '-5%' },
        font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 18px/27px var(--unnamed-font-family-frutiger-lt-std)',
        letterSpacing: 'var(--unnamed-character-spacing-0)',
        color: 'var(--unnamed-color-4fafcd)',
        // font: 'normal normal bold 18px/27px Frutiger LT Std',
        color: '#4FAFCD',
    }
}


export default TypesOfSentencing